import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import Page from "../../components/Layout/Page";
import Container from "../../components/Layout/Container";
// import BtnFilled from "../../components/reusable/BtnFilled";
import ContactBlock from "../../components/ContactBlock";

import Seo from "../../components/reusable/Seo";
const CaseStudy1 = () => {
  return (
    <Page className="case-study">
      <Seo
        title="Case Study : Machine Learning Platform to improve AI-powered search "
        path="case-studies/machine-learning-for-ai-powered-search/"
        description="Built a modern cloud-native Machine Learning Platform for one of the largest American retailers in the home improvement"
      />
      <section className="hero">
        <Container>
          <div className="text">
            <div className="h1-prefix">
              <span>Case Study</span>
            </div>
            <h1>
              <b>Machine Learning Platform to improve AI-powered search</b>
            </h1>
          </div>
          <figure>
            <StaticImage
              src="../../images/casestudy/aiml-platform.jpg"
              alt="modern cloud-native Machine Learning Platform"
              layout="fullWidth"
              loading="eager"
              placeholder="blurred"
            />
          </figure>
        </Container>
      </section>
      <section className="overview">
        <Container>
          <p className="intro">
            <b>
              Built a modern cloud-native Machine Learning Platform for one of
              the largest American retailers in the home improvement
            </b>
          </p>
          <p>
            Client is a Fortune 50 home improvement company serving
            approximately 20 million customers a week in North America. Client
            wanted to re-platform their decade old platform to cloud.
          </p>
          <br />
          <div className="keypoints">
            <ul>
              <li>
                <span>
                  More than 2.2 million products and $98 billion in revenue{" "}
                </span>
              </li>
              <li>
                <span>Witnessed 70% growth in online traffic (2020) </span>
              </li>
            </ul>
          </div>
        </Container>
      </section>
      <section className="biz-value">
        <Container>
          <h2>
            <b>Business Value Delivered</b>
          </h2>
          <div className="items">
            <div className="item">
              <div>
                <strong>$100M</strong> <span>Increase in sales</span>
              </div>
            </div>
            <div className="item">
              <div>
                <strong>50%</strong>{" "}
                <span>Reduction in model development and training</span>
              </div>
            </div>
            <div className="item">
              <div>
                <strong>70% </strong> <span>Increase in efficiency</span>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="challenges">
        <Container>
          <h2>
            <b>Business Challenges </b>
          </h2>
          <ul>
            <li>
              <span>Ineffective use of collected data</span>
            </li>
            <li>
              <span>Isolated data sources with no discovery mechanism </span>
            </li>
            <li>
              <span>
                Duplication of effort across multiple teams of Data Scientists &
                Engineers working in silos{" "}
              </span>
            </li>
            <li>
              <span>
                High turnaround time for building and deploying end-to-end ML
                Solutions from Data Generation to Model Training to Production
                Deployment
              </span>
            </li>
          </ul>
        </Container>
      </section>
      <section className="solution">
        <Container>
          <h2>
            <b>Solutions</b>
          </h2>
          <ul>
            <li>
              <span>
                Created a unified data lake as single source of truth for data
                across organization
              </span>
            </li>
            <li>
              <span>
                Set up common MLOps platform for shared model building across
                teams.
              </span>
            </li>
            <li>
              <span>
                Customized the platform to build user focused AI-ML services
              </span>
            </li>
          </ul>
        </Container>
      </section>
      <ContactBlock></ContactBlock>
    </Page>
  );
};

export default CaseStudy1;
